import { dev } from '$app/environment';
import * as Sentry from '@sentry/sveltekit';
import { handleErrorWithSentry, replayIntegration } from '@sentry/sveltekit';

Sentry.init({
	dsn: 'https://d9b74421e2c8e71902f146bd2d1894a1@o4507658177085440.ingest.us.sentry.io/4507826968788992',
	tunnel: '/api/tunnel',
	tracesSampleRate: 1,

	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1,

	integrations: [
		replayIntegration({
			maskAllText: false,
			maskAllInputs: true,
			blockAllMedia: false,
			useCompression: false,
		}),
	],

	environment: dev ? 'development' : 'production',
	enabled: !dev,
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
